import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import Subfooter from '../../../components/Subfooter';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/background-chemistry.jpg';
import '../../../styles/pages/_unit-sections.scss';

const Chemistry = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'OVERVIEW',
    linkLabel: 'CHEMISTRY IN THE EARTH SYSTEM',
    to: '/classroom-instruction/chemistry/',
  };

  const {
    chemAntibodyAvengers,
    chemBenefitsOfBiologics,
    chemCancerAndCompanionDiagnostics,
    chemGoingGreenInBiopharma,
  } = getKeyedResources(data);

  return (
    <Layout title="Chemistry in the Earth System">
      {/* -----------------UNIT BANNER----------------------- */}
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-chemistry-unit1@2x.jpg"
        guidSrc="d11fcf94-a9f4-4f63-81b0-288e78eb4b33"
        posterSrc={'unit-1-chemistry-intro-video-still.gif'}
        // breadcrumbs={heroBreadcrumbs}
        color={'blue'}
      >
        <h1 className=" mb-0 pb-0">CHEMISTRY IN THE EARTH SYSTEM</h1>
        <p className="pb-2">
          Use the essentials of chemistry as a key to unlocking biotechnology
          <br /> applications in students’ own lives and communities.
        </p>
      </Hero>
      {/* ----------------- AT A GLANCE RIBBON ----------------- */}
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>CHEMISTRY IN THE EARTH SYSTEM DIGITAL LESSON BUNDLES</div>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Explore the ways that chemistry can be used to develop
                  innovative new biotechnologies and empower students to create
                  a healthier future.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>4 Three-Day Lessons</li>
                <li>4 Classroom Presentations</li>
                <li>Flexible Extension Resources</li>
                <li>45-Minute Sessions</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-topics-covered.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                TOPICS COVERED
              </h3>
              <ul className="course__list">
                <li>Immunology</li>
                <li>DNA Health</li>
                <li>Disease Diagnostics & Treatment</li>
                <li>Sustainability</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>NGSS Aligned</li>
                <li>Project Driven</li>
                <li>Data Based</li>
                <li>Grounded in Inquiry</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ----------------- COURSE CARDS INTRO ----------------- */}
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">Getting Started</h3>
              <p>
                Use a chemistry lens to introduce students to exciting
                innovations in biotechnology. These hands-on, experiential
                resources inspire students to find connections between important
                breakthroughs in biotech and their own lives.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-Chem.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 33.1 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        {/* ----------------- COURSE CARDS ----------------- */}
        <Container fullWidth>
          <Row>
            {/* LESSON 1*/}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={chemAntibodyAvengers.tophat}
                tophatClass={`${chemAntibodyAvengers.page}__resource-tophat`}
                ribbon={chemAntibodyAvengers.ribbon}
                ribbonClass={`${chemAntibodyAvengers.page}__resource-ribbon`}
                img=""
                duration={chemAntibodyAvengers.duration}
                subject={chemAntibodyAvengers.subject}
                subjectClass={`${chemAntibodyAvengers.page}__resource-subject`}
                title={chemAntibodyAvengers.title}
                description={chemAntibodyAvengers.description}
                actions={chemAntibodyAvengers.actions}
              />
            </Column>
            {/* LESSON 2 */}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={chemBenefitsOfBiologics.tophat}
                tophatClass={`${chemBenefitsOfBiologics.page}__resource-tophat`}
                ribbon={chemBenefitsOfBiologics.ribbon}
                ribbonClass={`${chemBenefitsOfBiologics.page}__resource-ribbon`}
                img=""
                duration={chemBenefitsOfBiologics.duration}
                lab={chemBenefitsOfBiologics.lab}
                subject={chemBenefitsOfBiologics.subject}
                subjectClass={`${chemBenefitsOfBiologics.page}__resource-subject`}
                title={chemBenefitsOfBiologics.title}
                description={chemBenefitsOfBiologics.description}
                actions={chemBenefitsOfBiologics.actions}
              />
            </Column>
            {/* LESSON 3 */}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={chemCancerAndCompanionDiagnostics.tophat}
                tophatClass={`${chemCancerAndCompanionDiagnostics.page}__resource-tophat`}
                ribbon={chemCancerAndCompanionDiagnostics.ribbon}
                ribbonClass={`${chemCancerAndCompanionDiagnostics.page}__resource-ribbon`}
                img=""
                duration={chemCancerAndCompanionDiagnostics.duration}
                subject={chemCancerAndCompanionDiagnostics.subject}
                subjectClass={`${chemCancerAndCompanionDiagnostics.page}__resource-subject`}
                title={chemCancerAndCompanionDiagnostics.title}
                description={chemCancerAndCompanionDiagnostics.description}
                actions={chemCancerAndCompanionDiagnostics.actions}
              />
            </Column>
            {/* LESSON 4 */}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={chemGoingGreenInBiopharma.tophat}
                tophatClass={`${chemGoingGreenInBiopharma.page}__resource-tophat`}
                ribbon={chemGoingGreenInBiopharma.ribbon}
                ribbonClass={`${chemGoingGreenInBiopharma.page}__resource-ribbon`}
                img=""
                duration={chemGoingGreenInBiopharma.duration}
                subject={chemGoingGreenInBiopharma.subject}
                subjectClass={`${chemGoingGreenInBiopharma.page}__resource-subject`}
                title={chemGoingGreenInBiopharma.title}
                description={chemGoingGreenInBiopharma.description}
                actions={chemGoingGreenInBiopharma.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <Subfooter content="Chemistry and biotechnology go hand in hand for addressing the needs of the human body and innovating targeted treatments for diseases." />
      </Section>
    </Layout>
  );
};

export default Chemistry;

export const query = graphql`
  query ChemistryResources {
    allResourcesJson(filter: { page: { eq: "chemistry" }, unit: { eq: 1 } }) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
